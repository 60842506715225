.container {
  background: var(--app-titlebar-background-color);
  height: var(--app-titlebar-height);
  -moz-user-select: none;
       user-select: none;

  /* Visible edge to help separate content */
  /* box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.03); */
}

.wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  --item-spacing: 2px;
  margin: 0 calc(var(--item-spacing) * -1);
}
.wrapper > * {
  margin: 0 var(--item-spacing);
}

.header-item {
  height: 100%;
  line-height: var(--app-titlebar-height);
}

.header {
  composes: header-item;
  flex: 1;

  /* overflow hidden hack */
  -webkit-mask-image: linear-gradient(#000, #000);
}

.drag {
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 0;

  /* https://github.com/electron/electron/blob/master/docs/api/frameless-window.md#draggable-region */
  -webkit-app-region: drag;
}

.clickable {
  z-index: 99999;
  -webkit-app-region: no-drag;
}

.title {
  composes: single-line from '~styles/text.css';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

.leftActions {
  composes: header-item clickable;
  order: -1;
}

.rightActions {
  composes: header-item clickable;
}

.actionButton {
  background: transparent;
  transition: background-color 80ms ease;
  border: none;
  color: var(--app-text-color);
  width: 45px;
  height: 100%;
  line-height: var(--app-titlebar-height);
  outline: none;
  opacity: 0.33;
  vertical-align: top;
}

.actionButton svg {
  transform: scale(0.7) translateY(5px);
}

.actionButton:hover {
  background: rgba(255, 255, 255, 0.3);
}

.actionButton.close:hover {
  background: rgba(255, 0, 0, 0.5);
}

.winIcon {
  /* Windows-only font */
  font-family: Marlett;
  font-size: 16px;
}

.container:hover .actionButton {
  opacity: 1;
}

.updateButton {
  composes: clickable;
  background: var(--color-highlight);
  color: var(--app-text-color);
  padding: 0 3px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
}

/* How to do this better? */
.updateButton svg {
  transform: scale(0.6);
}
