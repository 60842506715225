.container {
  composes: glass rounded from '~styles/layout.css';
  display: flex;
  height: 40px;
  user-select: none;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;

  min-width: 40px;
  height: 40px;
  padding: 0 10px;
  border: none;
  outline: none;

  /* font-size: 0; */

  color: var(--app-text-color);
  background: transparent;
  /* box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.44); */
}

.button:hover {
  background: rgba(255, 255, 255, 0.11);
}

.button:disabled {
  opacity: 0.5;
}

.button:hover:disabled {
  background: transparent;
}

.button:first-child {
  border-radius: var(--rounded-radius) 0 0 var(--rounded-radius);
}

.button:last-child {
  border-radius: 0 var(--rounded-radius) var(--rounded-radius) 0;
}

.buttonEnabled {
  color: var(--color-highlight);
}

.buttonListItem {
  padding: 8px 12px;
  color: var(--app-text-color);
  background: transparent;
  text-align: left;

  display: flex;
  align-items: center;
}

.buttonListItem svg {
  margin-right: 10px;
}

.buttonListItem:hover {
  background: rgba(255, 255, 255, 0.11);
}

.spacer {
  flex-grow: 1;
  margin: 0 5px;
}

.menuDivider {
  width: 100%;
  border-color: rgba(255, 255, 255, 0.1);
}
