.container {
  position: relative;
}

.menu {
  composes: glass rounded-top from '~styles/layout.css';
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  width: 200px;

  display: flex;
  flex-direction: column;

  text-align: left;
}
