.container {
  text-align: center;
}

.header {
  composes: h2 from '~styles/typography.css';
  margin-bottom: 1rem;
}

.container button:first-child {
  margin-right: 1rem;
}
