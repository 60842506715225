:root {
  --rounded-radius: 8px;
}

.dark-overlay {
  background: var(--app-titlebar-background-color);
}

.glass {
  background: rgba(60, 60, 60, 0.54);

  /* TODO: Check back later to see if background blur is less buggy */
  /* background: rgba(120, 120, 120, 0.33); */
  /* backdrop-filter: blur(20px); */
  /* -webkit-mask-image: linear-gradient(#000, #000); */
}

.circle {
  border-radius: 50%;
  overflow: hidden;
}

.rounded {
  border-radius: var(--rounded-radius);
}

.rounded-top {
  border-radius: var(--rounded-radius) var(--rounded-radius) 0 0;
}

.rounded-bottom {
  border-radius: 0 0 var(--rounded-radius) var(--rounded-radius);
}

.absolute-full {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.absolute-vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.fill {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fill-center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-vertical {
  display: flex;
  align-items: center;
}

.row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

/*
  Custom Scrollbar
*/

.scroller {
  /** Hide scrollbar in Firefox */
  scrollbar-width: none;
}

.scroller::-webkit-scrollbar {
  width: 12px;
}

.scroller:hover::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.11);
}

.scroller::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.22);
}

.scroller::-webkit-scrollbar-thumb,
.scroller::-webkit-scrollbar-track-piece {
  background-clip: padding-box;
  border: 3px solid transparent;
  border-radius: 7px;
}

.dark-scroller {
  composes: scroller;
}

.dark-scroller:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.33);
}

.dark-scroller::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.44);
}

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}

.clearfix:after {
  clear: both;
}
