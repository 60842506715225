.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
}

.tabList {
  display: flex;
  flex: 0 0 auto;
}

.tabItem {
  flex: 0 1 auto;
}

.tabButton {
  padding: 0 0 2rem;
}

.tabItem:not(:last-child) .tabButton {
  padding-right: 2rem;
}

.tabLabel {
  font-size: 1.2rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 0.4;
  transition: opacity 140ms ease-out;
}

.tabButton:hover .tabLabel,
.selected .tabLabel {
  font-weight: bold;
  opacity: 1;
}

.content {
  composes: dark-scroller from '~styles/layout.css';
  height: 100%;
  flex: 0 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
}

@media only screen and (max-width: 840px) {
  .tabList {
    flex-direction: column;
  }
}
