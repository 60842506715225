.container {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
}

.media {
  min-width: 0;
  height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 0 0%;
  align-content: center;
  justify-content: space-between;
}

.favicon {
  align-self: center;
  margin-right: 0.5rem;
  width: 1.25rem;
}

.title {
  composes: single-line from '~styles/text.css';
  flex: 1 1 100%;
}

.authorContainer {
  margin-left: auto;
}

.duration,
.author {
  font-size: 14px;
  flex: 0 1 auto;
}

.authorLabel {
  text-transform: uppercase;
  font-size: 8px;
  margin-right: 4px;
}

.author {
  composes: single-line from '~styles/text.css';
  display: inline-block;
  max-width: 140px;
  vertical-align: middle;
}

.menuBtn {
  opacity: 0;
}

.menuBtn:focus,
.container:hover .menuBtn {
  opacity: 1;
}
