.container {
  composes: absolute-full from '~styles/layout.css';
  composes: fill-center from '~styles/layout.css';
}

.row {
  width: 100%;
  max-width: 1200px;
  margin: 0 var(--overlay-spacing);
  margin-bottom: var(--overlay-spacing);
}
