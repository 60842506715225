.container {
  position: relative;
  font-size: 0;
}

.image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.selected .image {
  box-shadow: 0 0 0 2px var(--color-highlight);
}

.badge {
  position: absolute;
  bottom: 0;
  right: 0;

  width: 50%;
  height: 50%;
  padding: 2px;
  border-radius: 50%;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}

.create {
  composes: container image;
  color: white;
  background: transparent;
  box-shadow: inset 0 0 0 2px currentColor;
}

.file {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file::-webkit-file-upload-button {
  cursor: pointer;
}

.file + .image {
  background: white;
}

.disabled {
  cursor: not-allowed;
}
