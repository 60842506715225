.container {
  background: var(--app-bg-home);
  min-height: 100vh;
  max-height: 100%;
  display: grid;
  grid-template:
    'header header' auto
    'nav intro' 1fr
    'footer footer' auto / auto 50%;
}

@media only screen and (max-width: 840px) {
  .container {
    grid-template:
      'header header' auto
      'nav nav' 1fr
      'intro intro' auto
      'footer footer' auto / auto 50%;
  }

  .social {
    flex-direction: column;
  }
}

.header {
  grid-area: header;
}

.logo {
  width: 48px;
  margin-right: 0.5rem;
}

.buildInfo {
  composes: blend-text from '~styles/text.css';
  font-size: 0.8rem;
  opacity: 0.4;
  margin-left: 1rem;
  font-family: 'Noto Mono', monospace;
  user-select: text;
}

.nav {
  grid-area: nav;
}

.intro {
  composes: blend-text from '~styles/text.css';
  grid-area: intro;
  font-weight: bold;
  overflow: hidden;
}

.intro a {
  text-decoration: underline;
}

.social {
  grid-area: footer;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.socialLink {
  display: inline-block;
  margin-right: 20px;
}

.socialIcon {
  filter: contrast(100) saturate(0);
  mix-blend-mode: overlay;
  opacity: 0.33;
  height: 32px;
}

@media (prefers-contrast: high) {
  .socialIcon {
    mix-blend-mode: normal;
  }
}

.socialIcon:hover {
  filter: invert(1);
  mix-blend-mode: normal;
  opacity: 1;
}

.btn {
  display: inline-block;
  margin-bottom: 15px;
}

.socialRight {
  display: flex;
  align-items: center;
  text-align: right;
}

.credits {
  composes: blend-text from '~styles/text.css';
  line-height: 1.2rem;
  text-align: right;
}

.credits a {
  font-weight: bolder;
}

.contributors {
  composes: credits;
  font-style: italic;
}
