.header {
  margin-bottom: 2.5rem;
}

.header h1 {
  font-size: 36px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
