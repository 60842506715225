.container {
  height: 3rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container .button svg {
  margin: 0 8px;
  opacity: 0.7;
}

.locationContainer {
  flex: 1 0 auto;
  height: 100%;
  padding: 6px;
}

.locationBar {
  height: 100%;
  display: flex;
  flex-direction: row;

  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.33);
}

.addressInput {
  flex: 1 0 auto;

  height: 100%;
  background: none;
  border: none;
  outline: none;

  padding: 0 8px;
}

.addressInput:not(:focus) {
  opacity: 0.5;
}

.button:disabled {
  opacity: 0.5;
}

.requestButtonContainer {
  padding: 6px;
  height: 100%;
}

.requestButtonContainer button {
  height: 100%;
}

.addHintTooltip {
  background-color: white !important;
  color: #222 !important;
  max-width: 360px !important;
  padding: 1rem 2rem !important;
  border: 2px solid var(--color-highlight);
}
