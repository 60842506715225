.container {
  composes: dark-overlay from '~styles/layout.css';
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  composes: scroller from '~styles/layout.css';
  display: flex;
  justify-content: center;
  overflow-y: auto;
  max-height: 100%;
  width: 100%;
  padding: 2rem;
}

.content.fill {
  height: 100%;
}

.close {
  composes: masked-glass from '~styles/effect.css';
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.22);
  opacity: 0.5;
}

.close:hover {
  opacity: 1;
}

.close svg {
  transform: scale(1.3);
}
