.progress {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.progress:hover .knob,
.knob:global(.active) {
  opacity: 1;
}

.progressTrack {
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.33);
  pointer-events: none;
  position: relative;
}

.progressBar {
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.66);
  will-change: width;
}

.knob {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;

  background: #fff;
  border: none;
  opacity: 0;

  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  will-change: left;

  transition: opacity 80ms ease-in-out;
}
