.container {
  --color-btn: rgba(255, 255, 255, 0.22);
  --color-btn-text: rgba(255, 255, 255, 0.88);
  --column-width: 47rem;
  --tips-height: 3rem;
  height: 100%;
  overflow: auto;
}

.main {
  min-height: calc(100vh - var(--tips-height));
  padding: 0 30px;
  padding-top: var(--tips-height);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.column {
  width: 100%;
  max-width: var(--column-width);
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
}

.row {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gridContainer {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  grid-auto-flow: dense;
}

.tips {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: var(--tips-height);
  overflow: hidden;
  border-bottom: 1px solid var(--color-transparent-light-30);
  transition: background-color 100ms ease-out;
}

.tipsExpanded {
  height: auto;
}

.tips:hover,
.tipsExpanded {
  backdrop-filter: blur(10px);
  background-color: var(--color-transparent-light-30);
}

/* fix bad contrast in firefox where backdrop blur isn't supported */
@-moz-document url-prefix() {
  .tipsExpanded {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.tipsExpanded svg {
  transform: rotate(180deg);
}

.tipsButton {
  width: 100%;
  height: var(--tips-height);
}

.tipsButton .column {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emojiList {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.emojiList li {
  padding-left: 2rem;
  position: relative;
  margin-top: 1rem;
}

.emojiList li:first-child {
  margin-top: 0;
}
.emojiBullet {
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  display: inline-block;
  text-align: center;
}

.button {
  background: var(--color-btn);
  color: var(--color-btn-text);
  transition: color 120ms ease-out, background-color 120ms ease-out;
  letter-spacing: 1px;
  border-radius: 4px;
  backdrop-filter: blur(10px);
}

.button:hover {
  background: var(--color-highlight);
  color: #fff;
}

.socialLink {
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 4rem;
  padding: 0.75rem 1rem;

  font-size: 1.5rem;
  text-align: center;
}

.text-container {
  flex: 1 0 auto;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: var(--column-width);
  text-align: center;
  font-size: 1.125rem;
  padding: 1rem 0;
}

.inputContainer {
  display: flex;
  margin-top: 2rem;
  min-width: 12rem;
  max-width: 100%;
}

.button,
.inputContainer input {
  padding: 0.5rem 1rem;
  line-height: 1.5rem;
}

.inputContainer .button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.inputContainer input {
  background: none;
  flex: 1 1 auto;
  border: none;
  color: #fff;
  border: 1px solid var(--color-btn);
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.uppercase {
  text-transform: uppercase;
}
