.header {
  composes: row-center from '~styles/layout.css';
}

.titleContainer,
.actions {
  padding: 10px;
}

.titleContainer {
  composes: row-center from '~styles/layout.css';
  flex-grow: 1;
  padding-right: 0;
  min-width: 0;
  height: 100%;
}

.title {
  composes: single-line from '~styles/text.css';
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 5px;
  text-overflow: clip;
}

.tagline {
  border: 1px solid rgba(255, 255, 255, 0.22);
  padding: 2px 4px;
  font-size: 12px;
  min-width: 18px;
  text-align: center;
}

.actions {
  composes: row-center from '~styles/layout.css';
}

.actions > *:not(:last-child) {
  margin-right: 0.5rem;
}
