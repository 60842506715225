.container {
  composes: fill from '~styles/layout.css';
  background: var(--app-bg-home);
  justify-content: center;
  align-items: center;
}

.column {
  width: 100%;
  max-width: 600px;
}

.column label[for] {
  margin-bottom: 0.5rem;
}

.column a {
  display: inline-block;
}

.column button,
.formControl {
  margin-top: 1.5rem;
}

.formControl > label {
  display: block;
}

.required {
  color: red;
  font-weight: bold;
  margin: 0.2rem;
}
