.textInput {
  color: var(--app-text-color);
  background: transparent;
  border: none;
  outline: none;
}

.textInput::-webkit-input-placeholder {
  opacity: 0.5;
}

.textInput:global(.invalid) {
  border-color: rgba(255, 0, 0, 0.66);
}

.text {
  composes: textInput;
  padding: 6px 8px;
  border: 1px solid var(--app-text-color);
  border-radius: 4px;
}

.text:disabled {
  /* opacity: 0.7; */
}

.textContainer {
  composes: text;
  display: flex;
}

.textContainer input {
  flex-grow: 1;
}

.inputGroup {
  display: flex;
  flex-wrap: nowrap;
}

.inputGroup > input {
  flex-grow: 1;
}

.inputGroup > *:not(:last-child) {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.inputGroup > *:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
