.container {
  position: relative;
}

.image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.selected .image {
  box-shadow: 0 0 0 2px var(--color-highlight);
}

.badge {
  position: absolute;
  bottom: 0;
  right: 0;

  width: 50%;
  padding: 2px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}
