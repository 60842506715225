.key {
  display: inline-block;
  margin: 0 4px;
  border-width: 2px 4px 5px;
  border-style: solid;
  border-radius: 4px;

  background: #afafaf;
  border-color: #c6c6c6 #bcbcbc #a8a8a8;

  color: #555;
  font-size: 0.8rem;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  font-style: normal;
}

.keyCap {
  background: linear-gradient(to right, #c6c6c6, #d6d6d6, #c6c6c6);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  line-height: 24px;
  padding: 0 7px;
  display: inline-block;
}
