.container {
  overflow: hidden;
  position: relative;
}

.webview {
  display: flex;
  flex: 1 auto;
  width: 100%;
  height: 100%;
}

.error {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
