.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  clip-path: inset(0 0); /* overflow: hidden not working for some reason */
}

.background {
  composes: absolute-full from '~styles/layout.css';
  z-index: 0;
  background-size: cover;
  background-position: 50% 50%;
  filter: blur(10px) brightness(0.3);
  transform: scale(1.05); /* hide blurred edges */
}

.text {
  z-index: 1;
  text-align: center;
}
