.container {
  composes: fill from '~styles/layout.css';
  /* background: var(--app-bg-lobby); */
  box-shadow: inset 1px 0 0 0 var(--color-transparent-light-10);
  background: var(--color-transparent-dark-66);
  padding-top: env(titlebar-area-height);
}

.tab {
  min-width: 0 !important;
}

.chat {
  width: 100%;
  flex: 1 1 auto;
}

.list {
  flex: 0 1 auto;
  max-height: 24%;
  box-shadow: inset 0 -1px 0 0 var(--color-transparent-light-10);
}

.list:not(:global(.collapsed)) {
  height: 14rem;
  min-height: 8rem;
}
