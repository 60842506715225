@import './common/reset.css';

/*
Variables
*/
:root {
  --color-transparent-dark-30: rgba(0, 0, 0, 0.3);
  --color-transparent-dark-50: rgba(0, 0, 0, 0.5);
  --color-transparent-dark-66: rgba(0, 0, 0, 0.6);
  --color-transparent-dark-80: rgba(0, 0, 0, 0.8);
  --color-transparent-light-10: rgba(255, 255, 255, 0.1);
  --color-transparent-light-30: rgba(255, 255, 255, 0.3);
  --color-transparent-light-40: rgba(255, 255, 255, 0.4);
  --color-transparent-light-50: rgba(255, 255, 255, 0.5);
  --color-nice-blue: #00bfff;

  --app-text-color: #fcfcfc;
  --app-titlebar-height: 30px;
  --app-titlebar-background-color: rgba(0, 0, 0, 0.6);

  /* --color-highlight: #f98673; */
  --color-highlight: rgb(249, 134, 115);
  --color-highlight-transparent: rgb(249, 134, 115, 0.7);
  --color-chat-text-outline: var(--color-transparent-dark-80);

  --app-bg-home: radial-gradient(
    circle at 10% -40%,
    #6f749e 0%,
    #9a8daf 31%,
    #d0a8b9 58%,
    #f8bbb1 80%,
    #fde6b1 100%
  );
  --app-bg-other: linear-gradient(200deg, #42275a, #734b6d);
  --app-bg-lobby: linear-gradient(
    170deg,
    #20202f 0%,
    #273550 16%,
    #416081 41%,
    #adacb2 78%,
    #eac3a2 100%
  );
}

/*
Fonts
*/

@font-face {
  font-family: 'Karla';
  src: url('/assets/fonts/karla-v5-latin-regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Karla';
  src: url('/assets/fonts/karla-v5-latin-700.woff2') format('woff2');
  font-weight: bold;
}
@font-face {
  font-family: 'Karla';
  src: url('/assets/fonts/karla-v5-latin-700italic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Karla';
  src: url('/assets/fonts/karla-v5-latin-italic.woff2') format('woff2');
  font-style: italic;
}

@font-face {
  /* font-family: 'noto_monoregular'; */
  font-family: 'Noto Mono';
  src: url('/assets/fonts/NotoMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/*
Default styles
*/

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#root,
.app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body,
input,
input::-webkit-input-placeholder,
textarea,
select {
  font-family: 'Karla', -apple-system, BlinkMacSystemFont, 'Segoe WPC', 'Segoe UI', 'Ubuntu',
    'Droid Sans', sans-serif;
  font-size: 16px;
  color: var(--app-text-color);
}

input::-webkit-input-placeholder {
  opacity: 0.6;
}

body {
  /** Make the app feel more native */
  -moz-user-select: none;
       user-select: none;
  cursor: default;
}

button {
  background: transparent;
  padding: 0;
  border: none;
  outline: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
}

button:disabled {
  cursor: auto;
}

::-moz-focus-inner {
  border: 0;
}

p {
  line-height: 1.5rem;
}

p:not(:last-child) {
  margin-bottom: 1rem;
}

a {
  color: unset;
  text-decoration: none;
}

a,
img {
  -webkit-user-drag: none;
}

b,
strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.link,
.link:visited,
.link:hover,
.link:active {
  color: #00bfff;
  text-decoration: none;
  -webkit-user-drag: none;
}

.link-alt,
.link-alt:visited,
.link-alt:hover,
.link-alt:active {
  color: var(--color-highlight);
  text-decoration: none;
  -webkit-user-drag: none;
}

::selection {
  background: rgba(249, 134, 115, 0.44);
}
